export default {
  word1001: 'Bahasa',
  word1002: 'Email',
  word1003: 'kode',
  word1004: 'Lupa password？',
  word1005: 'Log masuk',
  word1006: 'pendaftaran',
  word1007: 'Perjanjian pengguna',
  word1008: 'Privasi pribadi',
  word1009: 'Tentang kita',
  word1010: 'Silakan masukkan akun',
  word1011: 'Silakan masukkan kata sandi',
  word1012: 'Nomor rekening telah dibekukan',
  word1013: 'Nama akun atau kata sandi salah',
  word1014: 'Berhasil masuk',
  word1015: 'Nama asli',
  word1016: 'Silakan masukkan angka di sebelah kanan',
  word1017: 'Dapatkan Kode Verifikasi',
  word1018: 'Silakan masukkan ulang kata sandi baru Anda',
  word1019: 'Silakan masukkan angka di sebelah kanan',
  word1020: 'Angka yang salah',
  word1021: 'Entri tidak cocok. Silakan masukkan ulang',
  word1022: 'Silakan masukkan nama asli Anda',
  word1023: 'Silakan masukkan alamat Email',
  word1024: 'Silakan masukkan ulang kata sandi baru Anda',
  Resend: 'Kirim ulang',
  word1025: ' detik untuk mengirim ulang',
  word1026: 'Pendaftaran berhasil',
  word1027: 'Silakan masukkan kode verifikasi Email',
  word1028: 'Kode verifikasi Email tidak boleh kosong',
  word1029: 'Kata sandi baru',
  word1030: 'Konfirmasi',
  word1031: 'Silakan masukkan kata sandi baru',
  word1032: 'Revisi berhasil',
  word1033: 'Sistem Interchange Bisnis',
  word1034: 'Kamar perdagangan Cina Indonesia',
  word1035: 'Akun anda telah dilarang',
  word1036: 'Daftar dan Verifikasi',
  word1037: 'Nama perusahaan',
  word1038: 'Alamat perusahaan',
  word1039: 'Nomor telepon perusahaan',
  word1040: 'Jabatan yang dipegang',
  word1041: 'Unggah foto / salinan terpindai Lisensi Bisnis',
  word1042: 'Hanya JPG/PNG, dan ukuran tidak boleh melebihi 20MB.',
  word1043: 'Unggah foto / salinan terpindai Sertifikat Pajak',
  word1044: 'Kirim',
  word1045: 'Lewati verifikasi sekarang, lanjutkan untuk menjelajah.',
  word1046: 'Pratinjau gambar.',
  word1047: 'Unggah foto / salinan terpindai Lisensi Bisnis',
  word1048: 'Unggah foto / salinan terpindai Sertifikat Pajak',
  word1049: 'Silakan masukkan nama perusahaan.',
  word1050: 'Silakan masukkan alamat perusahaan.',
  word1051: 'Nomor telepon perusahaan tidak boleh kosong',
  word1052: 'Silakan masukkan pekerjaan Anda.',
  word1053: 'Berhasil dihapus.',
  word1054: 'Berhasil dikirim.',
  word1055: 'Maaf, email ini sudah terdaftar. Silakan gunakan email lain.',
  word1056: 'Daftar Langsung Tanpa Verifikasi',
  word1057:
    'Sistem ini ditujukan untuk interkoneksi lintas batas, dan kami menyarankan Anda untuk memverifikasi perusahaan Anda untuk menikmati lebih banyak peluang bisnis dan sumber daya hukum',
  word1058: 'Pemberitahuan',
  word1059: 'Lewati verifikasi',
  word1060: 'Pergi untuk Memverifikasi',
  word1061: 'Email pengguna sudah ada atau sudah dihapus',
  word1062: 'Panjang kata sandi harus antara 8 hingga 16 karakter',
  word1063: 'Itu format email yang salah',
  word1064: 'Kode verifikasi email tidak valid, silakan dapatkan lagi',
  word1065: 'Kode verifikasi email salah',
  word1070: 'Kirim ulang',
  AgainInputPassword: 'Silakan masukkan ulang kata sandi Anda',
  AccountInexistence: 'Akun tidak ada, dan kata sandi tidak dapat diubah.',
  DeleteTip: 'Akun Anda telah dihapus. Silakan hubungi administrator Kamar Dagang Anda.',
  DearUser: 'Pengguna yang Terhormat,',
  NotShow: 'Tidak lagi ditampilkan',
  PromptContentOne: 'Halo, sistem ini adalah sistem IT internal yang diciptakan oleh ',
  PromptContentTwo:
    ' untuk anggota perusahaan. ia menyempurnakan informasi komersial dan sumber daya hukum dari sumber rumah dan internasional di ',
  PromptContentThree:
    ' untuk penggunaan perusahaan. ia cocok untuk pemegang saham, manajer umum, kepala departemen pemasaran, kepala departemen penjualan, kepala departemen pembelian, dan kepala departemen hukum anggota perusahaan. Silakan konfirmasi bahwa organisasi Anda adalah anggota dari ',
  PromptContentFour:
    ' dan benar-benar mengisi informasi pribadi Anda sebelum mendaftar. Sistem manajemen backend kami akan membeku atau menghapus akun bukan anggota. Silakan diberitahu.',
  PromptContentFive: 'Sementara itu terima kasih telah mengikuti ',
  PromptContentSix: ', dan terima kasih atas dukungan anda juga!',
  PromptContentSeven: ' sekretariat',
  QuickPaymentTip:
    'Kuota biaya anggota kamar dagang Anda telah ditetapkan. Mohon selesaikan pembayaran dengan cepat, atau Anda akan menghadapi konsekuensi pada [tanggal spesifik]',
  UnableLoginTip: 'Tidak dapat masuk',
  Prompt: 'Pemberitahuan',
  MembershipDues: 'Bayar biaya keanggotaan',
  Determine: 'Konfirmasi',
  MembershipExpirationTip: 'Keanggotaan Anda telah berakhir. Mohon perbaharui dengan membayar biaya keanggotaan',
  Cancel: 'Batalkan',
  ImgOnly: 'Format gambar hanya bisa jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'Ukuran gambar tidak boleh melebihi 2MB',
  UploadSuccess: 'Berhasil diunggah'
}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      title: '系统登录|System Login|Log masuk sistem'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
    meta: {
      title: '用户注册|Registration|Pendaftaran pengguna'
    }
  },
  {
    path: '/companyVerify',
    name: 'companyVerify',
    component: () => import(/* webpackChunkName: "companyVerify" */ '@/views/CompanyVerify.vue'),
    meta: {
      title: '公司验证|Company Verify|Verifikasi perusahaan'
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import(/* webpackChunkName: "forgetPassword" */ '@/views/ForgetPassword.vue'),
    meta: {
      title: '修改密码|Change Password|Ubah sandi'
    }
  }
]

const router = new VueRouter({
  routes
})

// 修改每个页面的 title
router.afterEach((to, from, next) => {
  if (to.meta.title) {
    const titleArr = to.meta.title.split('|')
    const locale = sessionStorage.getItem('locale')
    if (titleArr.length) {
      if (locale === 'zh-CN') {
        // 中文
        document.title = titleArr[0]
      } else if (locale === 'en-US') {
        // 英文
        document.title = titleArr[1]
      } else if (locale === 'bahasa-Indonesia') {
        // 印尼语
        document.title = titleArr[2]
      }
    }
  }
})

export default router
